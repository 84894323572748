import {
  request
} from "./request";
import config from '@/config'
import md5 from 'js-md5';


export function guest(apiName, data) {
  let params = pack(apiName, data);
  return request({
    url: 'guest',
    method: 'post',
    data:params
  })
}

export function getUserInfo(apiName,data) {
  let params = pack(apiName,data)
  return request({
    url: 'user',
    method: 'post',
    data:params
  })
}

// export function getJSON() {
//   return request({
//     url: 'user',
//     method: 'get',
//   })
// }

export function upImg(url,data) {
  return request({
    url,
    method: 'post',
    data
  })
}




function pack(apiName, data) {
  let params = {
    appid: config.appid,
    body: data,
    timestamp: new Date().getTime().toString(),
    type: 'MiniPos.' + apiName,
    version: '1.0'
  }
  return sign(params);
}

/**
 * 数据签名
 * @param params
 * @returns {params:Array}
 */
function sign(param) {
  var appKey = config.appKey;
  param.body = encodeURI(JSON.stringify(param.body));
  var signStr = "appid=" + param.appid + "&body=" + param.body + "&timestamp=" + param.timestamp + "&type=" + param.type + "&version=" + param.version + appKey;
  param.sign = md5(signStr).toString().toUpperCase();
  return param;
}