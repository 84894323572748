<template>
  <!-- <b-table hover responsive="sm" :items="items" head-variant="dark"/> -->
  <b-card no-body>
    <b-button
      v-if="specialBtn"
      variant="primary"
      @click="outTable(id, title)"
      class="spec-btn"
    >
      导出Excel
    </b-button>
    <div v-if="specialBtn" style="height: 1.6rem"></div>
    <b-card-header v-if="isShowHeader">
      <b-card-title class="mb-10">{{ title }}</b-card-title>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        @click="outTable(id, title)"
      >
        导出Excel
      </b-button>
    </b-card-header>
    <!-- responsive="sm"屏幕为sm时开启水平滚动 -->
    <b-table
      hover
      :fixed="isFixed"
      :items="items"
      :id="id"
      :fields="fields"
      tdClass="td-class"
    >
      <!-- Column: Actions -->
      <template #cell(action)="data">
        <div @click="editRowData(data)">
          <feather-icon size="21" icon="EditIcon" />
        </div>
      </template>
      <!-- Column: Actions -->
      <template #cell(SMSAction)="data">
        <div @click="noticMsg(data)">
          <feather-icon size="21" icon="MessageSquareIcon" />
        </div>
      </template>
    </b-table>
    <slot></slot>
  </b-card>
</template>

<script>
import {
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

import FileSaver from "file-saver";
import * as XLSX from "xlsx";
export default {
  props: {
    fields: Array,
    items: Array,
    title: String,
    id: [String],
    isShowHeader: {
      type: Boolean,
      default: true,
    },
    specialBtn: {
      type: Boolean,
      default: false,
    },
    getAllData: Function,
    isFenYe: {
      type: Boolean,
      default: false,
    },
    //是否固定每行宽度
    isFixed: {
      type: Boolean,
      default: true
    }
  },
  components: {
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {};
  },
  methods: {
    outTable(id, title) {
      this.exportExcel(id, title);
    },
    exportExcel(id, title) {
      let xlsxParam = { raw: true }; // 导出的内容只做解析，不进行格式转换
      let wb = XLSX.utils.table_to_book(
        document.querySelector("#" + id),
        xlsxParam
      );
      let wbout = XLSX.write(wb, {
        bookType: "xlsx",
        bookSST: true,
        type: "array",
      });
      try {
        FileSaver.saveAs(
          new Blob([wbout], { type: "application/octet-stream" }),
          `${title}.xlsx`
        );
      } catch (e) {
        if (typeof console !== "undefined") {
          console.log(e, wbout);
        }
      }
      return wbout;
    },
    //编辑打印机
    editRowData(row) {
      // console.log(row.item);
      this.$emit("handleEditRowData", row.item);
    },
    //发送短信通知
    noticMsg(row) {
      this.$emit("handleNoticMsg", row.item);
    }
  },
};
</script>

<style lang="scss" scoped>
.spec-btn {
  position: absolute;
  top: -2.1rem;
  right: 0rem;
}

// .td-class {
// }
// ::v-deep td {
//   // text-overflow:ellipsis;
//   // overflow:hidden;
//   // white-space:nowrap;
// }
</style>