<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card>
          <MyTable
            :fields="SMSFields"
            :items="SMSItems"
            :isShowHeader="false"
            :isFixed="false"
            @handleNoticMsg="handleNoticSMS"
          ></MyTable>
        </b-card>
      </b-col>
    </b-row>
    <!-- 短信通知model -->
    <b-modal
      id="SMS-model"
      centered
      title="提示"
      ok-only
      ok-title="通知"
      @ok="sendSMS"
    >
      <b-card-text> 是否通知店家充值短信额度 </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import { BCard, BCardText, BRow, BCol } from "bootstrap-vue";

import MyTable from "@/components/MyTable.vue";

import { getUserInfo } from "@/utils/api";
import { changeTips } from "@/utils/util";

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,

    MyTable,
  },
  data() {
    return {
      SMSFields: [
        {
          key: "sid",
          label: "门店id",
        },
        {
          key: "storeName",
          label: "店名",
        },
        {
          key: "address",
          label: "城市地址",
        },
        {
          key: "phone",
          label: "电话",
        },
        {
          key: "balanceSms",
          label: "短信余额",
        },
        {
          key: "SMSAction",
          label: "操作",
        },
      ],
      SMSItems: [],
      currentSMS: null,
    };
  },
  created() {
    this.getsmsBalanceList();
  },
  methods: {
    getsmsBalanceList() {
      getUserInfo("sysAdminWeb.smsBalanceList", {}).then((res) => {
        if (res && res.data && res.data.code === "200") {
          this.SMSItems = res.data.data;
          this.SMSItems.forEach(it => {
            it.address = it.addressCity + it.addressDetail
          })
        } else {
          return changeTips(res.data.msg, "warning");
        }
      });
    },
    //弹出通知模态框
    handleNoticSMS(e) {
      console.log(e, "发送短信通知");
      this.currentSMS = e;
      this.$bvModal.show("SMS-model");
    },
    //发送通知
    sendSMS() {
      const SMSparams = {};
      console.log("确认发送通知");

      // getUserInfo("", SMSparams).then((res) => {
      //   if (res && res.data && res.data.code === "200") {
      //     console.log(res);
      //     this.getsmsBalanceList()
      //     return changeTips(res.data.msg, "primary");
      //   } else {
      //     return changeTips(res.data.msg, "warning");
      //   }
      // });
    },
  },
};
</script>

<style>
</style>
