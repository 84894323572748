import axios from "axios";
import router from "@/router";
import BvToast from '@/utils/bvToast'
import store from "@/store";


export function request(config) {
    const instance = axios.create({
        // baseURL: 'https://mp.iguaka.com/api/gate/',
        baseURL: 'http://gpos.dev.iguaka.com/api/gate/',
        timeout: 5000,
    })
    //1)请求拦截
    instance.interceptors.request.use(res => {
        if (localStorage.getItem('userInfo')) {
            let userInfo = JSON.parse(localStorage.getItem('userInfo'))
            res.headers['uid'] = userInfo.id.toString()
            res.headers['token'] = userInfo.token
            res.headers['companyId'] = userInfo.companyId.toString()
            res.headers['storeId'] = userInfo.storeId.toString()
        }

        return res
    }, err => {
        // console.log(err);
    })
    //2)响应拦截
    instance.interceptors.response.use(res => {
        // 登陆过期
        if (res.data.code === "444") {
            store.commit('CLEAR_USER')
            router.push({
                name: 'login'
            })
            BvToast.toast('登陆失效，请重新登录', {
                title: `提示`,
                variant: "warning",
                solid: true,
            })

        }
        return res
    }, err => {})
    return instance(config)

}