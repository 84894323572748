import BvToast from '@/utils/bvToast'

export function changeTips(val,color) {
  BvToast.toast(val, {
    title: `提示`,
    variant: color,
    solid: true,
  })
}

